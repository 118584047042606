import { api } from ":mods";
import { ENDPOINTS } from "../const";
import { VerifyEmailProps } from "../models";

export async function verifyEmail(data: VerifyEmailProps) {
  return api
    .putAuth(ENDPOINTS.verify_email, data)
    .then(async (res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
